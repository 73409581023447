// Imports

import React from 'react';
import {Provider} from 'react-redux';
import {Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import Alerts from './components/alerts/alerts'
import store from './store';

// Options

const alertOptions = {
  timeout: 3000,
  position: 'bottom center',
};

// Wrapper

export default ({ element }) => (
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Alerts />
      {element}
    </AlertProvider>
  </Provider>
);
