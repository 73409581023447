// Imports

import {combineReducers} from 'redux';

import messagesReducer from './messages';
import errorsReducer from './errors';
import uiReducer from './ui';

// Root Reducer

export default combineReducers({
  messagesReducer,
  errorsReducer,
  uiReducer,
});
