// Imports

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers/root';

// Middleware

const middlewares = [thunk];

// Store

const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
);

// Export

export default store;