// Imports

import { 
    BEGIN_LOADING, 
    END_LOADING, 
    REDIRECT_TO 
} from '../actions/types';

// State

const initialState = {
    isLoading: false,
    loadingTitle: 'Submitting',
    loadingSubtitle: 'Please be patient while we submit your information.',
    redirectEndpoint: null,
};

// Reducer

export default function(state = initialState, action) {
  switch (action.type) {
    case BEGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        loadingTitle: action.payload['title'],
        loadingSubtitle: action.payload['subtitle'],
      }
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case REDIRECT_TO:
      return {
        ...state,
        loadingTitle: action.payload['title'],
        loadingSubtitle: action.payload['subtitle'],
        redirectEndpoint: action.payload['endpoint']
      }
    default:
      return state;
  }
}